// Angular Files
import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnDestroy, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

// Angular Material Files
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectModule } from '@angular/material/legacy-select';

// Other Files
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { Subscription } from 'rxjs';

// Payment Integration Files

// Teller Online Files
import { ECheckAccountOwnerTypeEnum, ECheckAccountTypeEnum, PaymentMethodData } from 'apps/public-portal/src/app/payment-integrations';

// Teller Online Library Files
import { TellerOnlineAppService } from 'teller-online-libraries/core';
import { TellerOnlineIconsModule } from 'teller-online-libraries/icons';
import { TellerOnlineValidationService, TellerOnlineSharedModule } from 'teller-online-libraries/shared';

@Component({
    selector: 'app-echeck',
    templateUrl: './echeck.component.html',
    styleUrls: ['./echeck.component.scss'],
    host: {
        class: 'echeck payment-method-details payment-method-details--echeck'
    }
})
export class ECheckComponent implements OnInit, OnDestroy {

    @Input() public allowSavePaymentMethod: boolean;
    @Input() public accountOwnerTypeEnabled: boolean = false;
    @Input() public accountTypeEnabled: boolean = true;
    @Input() public requireDualEntryForECheck: boolean = false;

    // constants, passed from base processor component to here
    @Input() public ECHECK_ACCOUNTTYPELIST: ECheckAccountTypeEnum[]
    @Input() public ECHECK_ACCOUNTOWNERTYPELIST: ECheckAccountOwnerTypeEnum[];
    @Input() public ECHECK_FIELDS: {
        checkname: string,
        checkaba: string,
        checkabavalidator: string,
        checkaccount: string,
        checkaccountvalidator: string,
        checktype: string,
        checkowner: string
    };

    // Declare @Input variables
    @Input() public paymentMethodData: PaymentMethodData;
    @Input() public formGroup: UntypedFormGroup;

    private _formChangeSubscription: Subscription;

    constructor(
        public validationService: TellerOnlineValidationService,
        private appService: TellerOnlineAppService,
    ) { }

    ngOnInit(): void {
        if (!this.ECHECK_FIELDS) {
            if (this.appService.debug) throw "ECHECK_FIELDS binding must be loaded before paymentMethodData binding";
        }

        // Add the echeck fields to the form, default name to the name from the parent component
        this.formGroup.addControl(this.ECHECK_FIELDS.checkname, new UntypedFormControl(this.paymentMethodData.billingInfo.fullName));
        this.formGroup.addControl(this.ECHECK_FIELDS.checkaccount, new UntypedFormControl(''));
        this.formGroup.addControl(this.ECHECK_FIELDS.checkaba, new UntypedFormControl(''));
        if (this.accountOwnerTypeEnabled) {
            this.formGroup.addControl(this.ECHECK_FIELDS.checkowner, new UntypedFormControl(''));
        }
        if (this.accountTypeEnabled) {
            this.formGroup.addControl(this.ECHECK_FIELDS.checktype, new UntypedFormControl(''));
        }
        if (this.requireDualEntryForECheck) {
            this.formGroup.addControl(this.ECHECK_FIELDS.checkaccountvalidator, new UntypedFormControl(''));
            this.formGroup.addControl(this.ECHECK_FIELDS.checkabavalidator, new UntypedFormControl(''));
        }

        this._formChangeSubscription = this.formGroup.valueChanges.subscribe(value => {
            this.paymentMethodData.echeckAccountNumber = value[this.ECHECK_FIELDS.checkaccount];
            this.paymentMethodData.echeckRoutingNumber = value[this.ECHECK_FIELDS.checkaba];
            this.paymentMethodData.billingInfo.fullName = value[this.ECHECK_FIELDS.checkname];
            if (this.accountOwnerTypeEnabled) {
                this.paymentMethodData.echeckAccountOwnerType = value[this.ECHECK_FIELDS.checkowner];
            }
            if (this.accountTypeEnabled) {
                this.paymentMethodData.echeckAccountType = value[this.ECHECK_FIELDS.checktype];
            }
        });
    }

    ngOnDestroy(): void {
        this._formChangeSubscription.unsubscribe();
    }
}
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatLegacyFormFieldModule,
        MatLegacyInputModule,
        MatLegacySelectModule,
        MatLegacyCheckboxModule,
        MatIconModule,
        NgxMaskDirective,
        NgxMaskPipe,
        TellerOnlineIconsModule,
        TellerOnlineSharedModule
    ],
    declarations: [
        ECheckComponent
    ],
    exports: [ECheckComponent],
    providers: [provideNgxMask()]
})
export class ECheckModule { }
