// Angular Files
import { DOCUMENT, Location } from '@angular/common';
import { Component, DoCheck, Inject, NgZone, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';

// Angular Material Files
import { MatIconRegistry } from '@angular/material/icon';

// Other External Files
import { BehaviorSubject, Subscription } from 'rxjs';

// Payment Integration Files
import {
    BasePaymentProcessorComponent,
    PaymentIntegrationFieldValue
} from 'apps/public-portal/src/app/payment-integrations/base/components';
import { CeleroService } from 'apps/public-portal/src/app/payment-integrations/celero/service';
import { PaymentMethodTypeEnum } from 'apps/public-portal/src/app/payment-integrations/base/models';
import { PaymentProcessorProvider } from 'apps/public-portal/src/app/payment-integrations/base';

// Teller Online Files
import { AuthService, CartService, InboundRedirectService } from 'apps/public-portal/src/app/core/services';

// Teller Online Library Files
import {
    TellerOnlineAppService,
    TellerOnlineErrorHandlerService,
    TellerOnlineSiteMetadataService
} from 'teller-online-libraries/core';
import {
    TellerOnlineMessageService,
    TellerOnlineValidationService
} from 'teller-online-libraries/shared';

// Give a type for CollectJS so typescript won't get mad because it's loaded in later via a script tag
declare const CollectJS: {
    config: any,
    configure: any,
    startPaymentRequest: any,
    inSubmission: boolean
}

@Component({
    selector: 'app-celero',
    templateUrl: './celero.component.html',
    styleUrls: ['./celero.component.scss'],
    host: {
        class: 'celero'
    }
})
export class CeleroComponent extends BasePaymentProcessorComponent implements OnInit, DoCheck, OnDestroy {
    // Public variables
    public celeroFieldsAvailable: boolean = false;

    // Private variables
    private _celeroFieldsValidated: BehaviorSubject<number> = new BehaviorSubject(0);
    private _submitting: boolean = false;
    private _erroredOut: boolean = false;
    /** Flag determining if collect.js has self-reported that it timed out */
    private _collectJSTimedOut: boolean = false;
    /** Id of the timer that manually tracks for the defined COLLETJS_TIMEOUT_DURATION if collect.js has timed out only during submit*/
    private _collectJSTimeout;

    // Constants
    private COLLECTJS_SCRIPT_ID = 'collectjs';
    private COLLECTJS_TIMEOUT_DURATION = 10000; //10s - based on the sample time provided by collect.js

    // #region BasePaymentProcessorComponent property overrides
    public set paymentMethodData(paymentMethodData) {
        if (this._formChangeSubscription) this._formChangeSubscription.unsubscribe();

        super.paymentMethodData = paymentMethodData;

        // dynamically add all of the cc fields to the controls for the form group
        if (paymentMethodData.type == PaymentMethodTypeEnum.CreditCard) {
            Object.keys(this.CC_FIELDS).filter(f => f != 'ccname').forEach(name => {
                this.paymentDetailsForm.addControl(name, new UntypedFormControl(new PaymentIntegrationFieldValue(null, '', name, this.CC_FIELDS[name])));
            });

            this.paymentDetailsForm.addControl(this.CC_FIELDS.ccname, new UntypedFormControl(paymentMethodData.billingInfo.fullName));
            // dynamically add all of the echeck fields to the controls for the form group
        } else if (paymentMethodData.type == PaymentMethodTypeEnum.ECheck) {
            Object.keys(this.ECHECK_FIELDS)
                .filter(f => !['checktype', 'checkownertype', 'checkaccountvalidator', 'checkabavalidator']
                .includes(f))
                .forEach(name => {
                    this.paymentDetailsForm.addControl(name, new UntypedFormControl(new PaymentIntegrationFieldValue(null, '', name, this.ECHECK_FIELDS[name])));
                });
            this.paymentDetailsForm.addControl(this.ECHECK_FIELDS.checktype, new UntypedFormControl(''));
        }

        if (paymentMethodData) {
            this._formChangeSubscription = this.paymentDetailsForm.valueChanges.subscribe((value) => {
                if (paymentMethodData.type == PaymentMethodTypeEnum.CreditCard)
                    paymentMethodData.billingInfo.fullName = value[this.CC_FIELDS.ccname];
                else
                    paymentMethodData.echeckAccountType = value[this.ECHECK_FIELDS.checktype];

            });
        }
    }
    public get paymentMethodData() {
        return super.paymentMethodData;
    }
    // #endregion

    // Subscriptions
    private _validationSubscription: Subscription;
    private _formChangeSubscription: Subscription;

    constructor(
        private celeroService: CeleroService,
        private errorHandlerService: TellerOnlineErrorHandlerService,
        @Inject(DOCUMENT) private document: Document,
        ngZone: NgZone,
        location: Location,
        appService: TellerOnlineAppService,
        siteMetadataService: TellerOnlineSiteMetadataService,
        inboundRedirectService: InboundRedirectService,
        cartService: CartService,
        authService: AuthService,
        messageService: TellerOnlineMessageService,
        validationService: TellerOnlineValidationService,
        paymentProvider: PaymentProcessorProvider,
        matIconRegistry: MatIconRegistry,
        domSanitizer: DomSanitizer
    ) {
        super(appService, ngZone, location, siteMetadataService, inboundRedirectService, cartService, authService, messageService, validationService, paymentProvider, matIconRegistry, domSanitizer);
        this.loading = true;
    }

    //#region OnInit Implementation

    ngOnInit() {
        super.ngOnInit();

        this.paymentProvider.configLoaded$.subscribe(loaded => {
            if (loaded) this._initializeCollectJS();
        });
    }

    //#endregion

    //#region DoCheck Implementation

    ngDoCheck(): void {
        super.ngDoCheck();
    }

    //#endregion

    //#region OnDestroy Implementation

    ngOnDestroy(): void {
        super.ngOnDestroy();
        if (this._formChangeSubscription) this._formChangeSubscription.unsubscribe();
        if (this._validationSubscription) this._validationSubscription.unsubscribe();
    }

    //#endregion

    //#region Event Handlers

    onSubmit_validateAndSubmit = () => {
        // Bail and trigger validation if the user has managed to submit the form
        // before CollectJS iframes have arrived.
        if (!this.celeroFieldsAvailable && this.paymentMethodData.type == PaymentMethodTypeEnum.CreditCard) {
            // iframes cannot be validated, so the error messages are applied directly
            // Show all field messages for the appropriate fields based on payment method
            Object.keys(this.CC_FIELDS).filter(f => f != 'ccname').forEach(field => {
                this.paymentDetailsForm.controls[field].value.error = this.getIntegrationFieldErrorMessage(field);
            });

            this.validationService.runValidation(this.paymentDetailsForm, null, false, this._generateAdditionalErrors());

            return;
        }

        this._submitting = true;
        this.appService.triggerPageLoading('Validating information...');
        if (this.paymentMethodData.type == PaymentMethodTypeEnum.CreditCard) {
            try {
                this._collectJSTimedOut = false;

                // trigger validation on the celero side of things and will potentially trigger the actual payment if everything is valid
                CollectJS.startPaymentRequest();
                // start a timer to track if collectJS has finished
                this._collectJSTimeout = setTimeout(() => {
                    if (this._collectJSTimedOut) {
                        this.appService.finishPageLoading();
                        // Collect.JS docs recommends either auto-retrying or prompting the user to retry
                        this.messageService.notification("An unexpected error occurred. You have not been charged. Please try again.", "error", 3000);
                    }
                }, this.COLLECTJS_TIMEOUT_DURATION + 5); //+ 5ms just to ensure it happens after collect.js returns

                // Wait 1s, then subscribe to changes in the celeroFieldsValidated,
                // this is to ensure we've at least started validation before checking how many of the celero fields are validated
                setTimeout(() => {
                    this._validationSubscription = this._celeroFieldsValidated.subscribe(count => {
                        this._handleCeleroFieldsValidated(count);
                    });
                }, 1000);
            } catch (e) {
                this._displayTimeoutError();
            }
        } else {
            if (this.validationService.runValidation(this.paymentDetailsForm, null, false)) {
                this._paymentToken = 'dummy';
                if (this.forEdit) {
                    this.savePaymentMethod();
                } else {
                    this.payCart();
                }
            } else {
                this.appService.finishPageLoading();
            }
        }
    };

    //#endregion


    //#region BasePaymentProcessorComponent Implementation

    public override async savePaymentMethod() {
        this.appService.triggerPageLoading('Saving information...');

        try {
            var response = await this.celeroService.savePaymentMethod({
                paymentMethodData: this.paymentMethodData,
                paymentToken: this._paymentToken,
                paymentMethodId: this.paymentMethodId
            });

            this.paymentMethodId = response.paymentMethodId;

            this.updateUrl();

            this.processingComplete.emit(response.last4);
        } catch (e) {
            this.processingError.emit(e);
        } finally {
            this.finishedDataEntry(true);
            this.appService.finishPageLoading();
        }
    }

    public override async payCart() {
        this.appService.triggerPageLoading("Processing payment, do not refresh your browser...");

        // Wait for the response.
        await this.cartService.updateCart({
            guestEmailAddress: this.paymentMethodData.billingInfo.email,
            rememberPaymentMethod: this.paymentMethodData.rememberPaymentMethod,
            paymentMethodId: null //unset any previously saved paymentMethodId incase a previous attempt to use a saved method was made
        });

        var proceed = await this.cartService.refreshCart(this._cartGuid, this.paymentMethodData.type);

        if (proceed) {
            try {
                var postPaymentResponse = await this.celeroService.payCart({
                    cartId: this._cartId,
                    paymentMethodData: this.paymentMethodData,
                    paymentToken: this._paymentToken,
                    inboundRedirectSourceId: this.inboundRedirectService.redirectSourceId
                });

                if (postPaymentResponse.cartStatus) {
                    this.processingComplete.emit(postPaymentResponse);
                } else {
                    // Display the appropriate message for the current payment method type
                    let notChargedMessage;
                    switch (this.paymentMethodData.type) {
                        case PaymentMethodTypeEnum.ECheck:
                            notChargedMessage = "Your account has not been charged.";
                            break;
                        case PaymentMethodTypeEnum.CreditCard:
                        default:
                            notChargedMessage = "Your card has not been charged.";
                            break;
                    }
                    this.messageService.notification("Unable to process payment. " + notChargedMessage + " Reason: " +
                        postPaymentResponse.errorMessage, "error", 5000);
                }

            } catch (e) {
                this.processingError.emit(e);
            } finally {
                this.finishedDataEntry(true);
                this.appService.finishPageLoading();
            }
        } else {
            this.finishedDataEntry(true);
            this.appService.finishPageLoading();
        }
    }

    //#endregion

    //#region helpers

    private _displayTimeoutError() {
        this.appService.finishPageLoading();
        this._collectJSTimedOut = false;
        if (this._erroredOut)
            return;

        this._erroredOut = true;

        this.messageService.alert("We have encountered an error and the page needs to be refreshed. Your payment was not processed. When you click okay, the page will automatically reload.", `Refresh Required`).then(async (confirm) => {
            this.finishedDataEntry();
            this.document.location.reload();
        });
    }

    private _handleCeleroFieldsValidated(count) {
        if (count == 3) {
            this.appService.consoleLog(this, "Validating form.");
            if (this._submitting && !this.validationService.runValidation(this.paymentDetailsForm, null, false, this._generateAdditionalErrors())) {
                this._submitting = false;
                clearTimeout(this._collectJSTimeout);
                this.appService.finishPageLoading();
            }

            // Remove the subscription, we want to have more granular control over when the form validation runs
            if (this._validationSubscription) this._validationSubscription.unsubscribe();
        }
    }

    private _initializeCollectJS() {
        let publicSecurityKey = this.paymentMethodData.type == PaymentMethodTypeEnum.CreditCard
            ? this.celeroService.publicSecurityKeyCredit
            : this.celeroService.publicSecurityKeyECheck;
        // check if the script has already been created
        if (!this.document.querySelector("#" + this.COLLECTJS_SCRIPT_ID) && publicSecurityKey) {
            const node = this.document.createElement('script');
            node.id = this.COLLECTJS_SCRIPT_ID;
            node.src = "https://connect.transactiongateway.com/token/Collect.js";
            node.type = 'text/javascript';
            node.async = false;
            node.setAttribute('data-tokenization-key', publicSecurityKey);
            node.setAttribute('data-variant', "inline");
            node.setAttribute('data-custom-css', `{
                "background": "transparent",
                "border": "none",
                "outline": "none",
                "padding": "0",
                "margin": "0",
                "width": "100%",
                "max-width": "100%",
                "vertical-align": "bottom",
                "text-align": "inherit",
                "box-sizing": "content-box",
                "font-size": "14px"
            }`);

            this.document.getElementsByTagName('head')[0].appendChild(node);
        }

        this._waitForCollectJS();
    }

    /** Every 100ms check if CollectJS is defined, if it is, configure it, otherwise, repeat */
    private _waitForCollectJS() {
        setTimeout(() => {
            if (typeof CollectJS != 'undefined' && this.paymentMethodData.type) {
                this._configureCollectJS();
            } else {
                this._waitForCollectJS();
            }
        }, 100);
    }

    private _configureCollectJS() {
        this.celeroFieldsAvailable = false;
        let fields: CeleroFields = new CeleroFields();
        // If the collect.js timeout hasn't triggered before we are reconfiguring after failed validation
        // then inSubmission will be true which will prevent any code from firing to validate the new fields
        CollectJS.inSubmission = false;

        if (this.paymentMethodData.type == this.PaymentMethodTypeEnum.CreditCard) {
            fields.ccnumber = new CeleroField();
            fields.ccnumber.title = this.CC_FIELDS.ccnumber;
            fields.ccnumber.placeholder = "0000 0000 0000 0000";
            fields.ccnumber.enableCardBrandPreviews = "true";
            fields.ccnumber.selector = "#celero-ccnumber";

            fields.ccexp = new CeleroField();
            fields.ccexp.title = this.CC_FIELDS.ccexp;
            fields.ccexp.placeholder = "MM / YY";
            fields.ccexp.selector = "#celero-ccexp";

            fields.cvv = new CeleroField();
            fields.cvv.display = "required";
            fields.cvv.title = this.CC_FIELDS.cvv;
            fields.cvv.placeholder = "***";
            fields.cvv.selector = "#celero-cvv";
        }

        // Keep track of if we're reconfiguring CollectJS or if this is the first time it's being configured
        let reconfigure = CollectJS?.config.fieldsAvailableCallback;
        CollectJS.configure({
            'fields': fields,
            'timeoutDuration': this.COLLECTJS_TIMEOUT_DURATION,
            'timeoutCallback': this.collectJSTimeoutCallback,
            'callback': this.collectJSCallback,
            "fieldsAvailableCallback": this.collectJSFieldsAvailableCallback,
            'validationCallback': this.collectJSValidationCallback,
        });

        //If we're reconfiguring, we need to manually trigger the fields available callback for some reason
        if (reconfigure) {
            // Trigger the notification that fields are available after a very short delay
            setTimeout(() => {
                this.collectJSFieldsAvailableCallback();
            }, 500);
        }
    }

    /**
     * Raises the _collectJSTimedOut flag when CollectJS is taking too long to respond.
     * The timeout duration is defined in the COLLECTJS_TIMEOUT_DURATION constant.
     * CollectJS calls this outside of angular, so ngZone is used to allow change detection.
     */
    public collectJSTimeoutCallback = () => {
        this.ngZone.run(() => {
            this.appService.consoleLog('Collect.js timed out');
            this.errorHandlerService.handleError({
                message: `Collect.js took longer than ${this.COLLECTJS_TIMEOUT_DURATION}` +
                    " to respond with tokenization. This could be due to an invalid or incomplete field, or poor connectivity."
            });
            this._collectJSTimedOut = true;
        });
    }

    /**
     * This is called when all of the celero fields are validated, regardless of anything else.
     * CollectJS calls this outside of angular, so ngZone is used to allow change detection.
     */
    public collectJSCallback = (response) => {
        if (!this._submitting) return;
        this.ngZone.run(() => {
            try {
                this._paymentToken = response.token;
                if (this.paymentMethodData.type == this.PaymentMethodTypeEnum.CreditCard) {
                    this.paymentMethodData.cardNumber = response.card.number;
                    this.paymentMethodData.cardExpiry = response.card.exp;
                    this.paymentMethodData.cardType = response.card.type;

                    this.paymentDetailsForm.controls.ccnumber.setValue(new PaymentIntegrationFieldValue(this.paymentMethodData.cardNumber, ''));
                    this.paymentDetailsForm.controls.ccexp.setValue(new PaymentIntegrationFieldValue(this.paymentMethodData.cardExpiry, ''));
                    this.paymentDetailsForm.controls.cvv.setValue(new PaymentIntegrationFieldValue(null, ''));
                }

                if (this.validationService.runValidation(this.paymentDetailsForm, null, true)) {
                    if (this.forEdit) {
                        this.savePaymentMethod();
                    } else {
                        this.payCart();
                    }
                }
            } catch (e) {
                this._displayTimeoutError();
            }
        });
    }

    /**
     * This is called once all of the iframes have loaded in to let us know that it's all ready.
     * CollectJS calls this outside of angular, so ngZone is used to allow change detection.
     */
    public collectJSFieldsAvailableCallback = () => {
        this.ngZone.run(() => {
            this.appService.consoleLog(this, "Celero Collect.js iframes loaded.");
            this.loading = false;
            this.celeroFieldsAvailable = true;
        });
    }

    /**
     * This is only called if the field has changed since the last time it was checked
     * This will be triggered manually as part of startPaymentRequest but also automatically
     * when the field is blurred (focus is taken away from one of the fields)
     * CollectJS calls this outside of angular, so ngZone is used to allow change detection.
     */
    public collectJSValidationCallback = (field: string, status: boolean, message: string) => {
        this.ngZone.run(() => {
            // if we've already validated all the fields and we're doing a revalidation, decrement the count first to indicate we're in the process
            if (this._celeroFieldsValidated.getValue() == 3)
                this._celeroFieldsValidated.next(this._celeroFieldsValidated.getValue() - 1);

            this.appService.consoleLog(this, "Celero Collect.js field validated: " + field);

            // Indicate to whatever cares that data has been entered
            this.startedDataEntry();

            var fieldControl = this.paymentDetailsForm.controls[field].value;
            if (!status) {
                fieldControl.value = null;
                // Set the message appropriately if the field is empty, otherwise leave it as is
                if (message.toLowerCase() == "field is empty") {
                    message = this.getIntegrationFieldErrorMessage(fieldControl.name);
                }
                fieldControl.error = message;
            } else {
                fieldControl.error = '';
            }
            this.paymentDetailsForm.controls[field].setValue(fieldControl);

            this._celeroFieldsValidated.next(this._celeroFieldsValidated.getValue() + 1);
        });
    }

    private _generateAdditionalErrors() {
        let additionalErrors: { [key: string]: string } = {};

        if (this.paymentDetailsForm.controls.ccnumber?.value.error) additionalErrors.ccnumber = this.paymentDetailsForm.controls.ccnumber.value.error;
        if (this.paymentDetailsForm.controls.ccexp?.value.error) additionalErrors.ccexp = this.paymentDetailsForm.controls.ccexp.value.error;
        if (this.paymentDetailsForm.controls.cvv?.value.error) additionalErrors.cvv = this.paymentDetailsForm.controls.cvv.value.error;

        if (this.paymentDetailsForm.controls.checkname?.value.error) additionalErrors.checkname = this.paymentDetailsForm.controls.checkname.value.error;
        if (this.paymentDetailsForm.controls.checkaba?.value.error) additionalErrors.checkaba = this.paymentDetailsForm.controls.checkaba.value.error;
        if (this.paymentDetailsForm.controls.checkaccount?.value.error) additionalErrors.checkaccount = this.paymentDetailsForm.controls.checkaccount.value.error;

        return additionalErrors;
    }

    //#endregion
}

class CeleroFields {
    ccnumber?: CeleroField;
    ccexp?: CeleroField;
    cvv?: CeleroField;
    checkaccount?: CeleroField;
    checkaba?: CeleroField;
    checkname?: CeleroField;
}

class CeleroField {
    selector?: string;
    title: string;
    placeholder: string;
    display?: string;
    enableCardBrandPreviews?: string;
}
