import { PaymentStatusEnumDto } from "./api/CoreWebApi";

export enum PaymentStatusEnum {
    Processing = "Processing",
    Processed = "Processed",
    Posting = "Posting",
    Posted = "Posted",
    PostingFailed = "Posting Failed",
    PendingReview = "Pending Review",
    Cancelled = "Cancelled",
    Refunded = "Refunded",
    Finalized = "Finalized",
}

export class PaymentStatusEnumConvertor {
    static fromDto(dto: PaymentStatusEnumDto): PaymentStatusEnum {
        return PaymentStatusEnum[dto];
    }

    static toDto(e: PaymentStatusEnum): PaymentStatusEnumDto {
        let dto: PaymentStatusEnumDto;

        switch(e) {
            case PaymentStatusEnum.PostingFailed:
                dto = PaymentStatusEnumDto.PostingFailed;
                break;
            case PaymentStatusEnum.PendingReview:
                dto = PaymentStatusEnumDto.PendingReview;
                break;
            // All the rest should be okay to be a direct mapping since there's no spaces in the name
            default:
                dto = PaymentStatusEnumDto[e];
                break;
        }

        return dto;
    }  
}

export class PaymentStatusEnumHelpers {
    private static DESCRIPTIONS: {[key: string]: string} = {};

    static PaymentStatusEnumHelpers() {
        
        this.DESCRIPTIONS[PaymentStatusEnum.Cancelled] = 
            `The payment was cancelled (is no longer valid) either by an admin user or automatically by a system process. 
            For automated processes it could indicate the payment was voided within Teller or voided within the third party payment provider.
            <br><br>
            Cancelled payments are NOT refunded automatically. 
            Refunds must be processed manually and then the payment must be marked as refunded.
            <br><br>
            The next logical state for this payment is Refunded, but it is optional (if no funds exchanged hands, it does not need to move to refunded).`;

        this.DESCRIPTIONS[PaymentStatusEnum.Refunded] = 
            `An admin user has indicated this payment was manually refunded.
            Refunded payments are considered final and do not change state after this point.`;
        
        
        this.DESCRIPTIONS[PaymentStatusEnum.Processing] = 
            `The user has attempted to make a payment, either from the checkout page within Teller Online (if enabled) or from the third party payment provider's website.
            <br><br>
            The payment should typically not remain in this state for longer than 30 minutes.
            <br><br>
            The next logical state for this payment is Processed or Pending Review.`;

        this.DESCRIPTIONS[PaymentStatusEnum.Processed] = 
            `The system has confirmed via an automated process that the user's payment was processed successfully by the third party payment provider and can be posted to Teller to be applied to the proper accounts.
            <br><br>
            The payment should typically not stay in this state more than a few seconds.
            <br><br>
            The next logical state for this payment is Posting.`;
        
        this.DESCRIPTIONS[PaymentStatusEnum.Posting] = 
            `The system has started posting the payment to Teller and Teller is applying the payment to the appropriate accounts.
            <br><br>
            The payment should typically not stay in this state more than a few minutes.
            <br><br>
            The next logical state for this payment is Posted or Posting Failed.`;
        
        this.DESCRIPTIONS[PaymentStatusEnum.PostingFailed] = 
            `An error was encountered either while attempting to send the payment to Teller, or while Teller was trying to apply the payment to the appropriate accounts.
            <br><br>
            The payment will be automatically retried every 15 minutes either until it succeeds or has been manually moved to Cancelled.
            If the error was temporary, the payment will succeed after a time.
            <br><br>
            Most likely, the error will not be temporary and will require intervention within Teller to resolve the error.
            If the issue is resolved within Teller, the payment can be manually synced with Teller or it will automatically sync within 15 minutes.
            <br><br>
            The next logical state for this payment is Posted once the error has been resolved, though it may be moved to Cancelled if it cannot be resolved.`;
        
        this.DESCRIPTIONS[PaymentStatusEnum.Posted] = 
            `The payment was successfully posted to Teller and applied to external systems.
            The system is sending a receipt to the user.
            <br><br>
            The payment should typically not stay in this state for more than a few seconds.
            <br><br>
            The next logical state for this payment is Finalized.`;
        
        this.DESCRIPTIONS[PaymentStatusEnum.PendingReview] =
            `The payment requires review as an error was encountered and could not be handled automatically.
            <br><br>
            Once the issue is resolved, the payment can be synced with Teller to set the appropriate status.
            `

        this.DESCRIPTIONS[PaymentStatusEnum.Finalized] = 
            `The payment was processed and posted sucessfully and a receipt was sent to the user.
            <br><br>
            This is considered a final state and a payment does not typically leave this state.
            <br><br>
            If, for some reason, a manual change was made to this payment in Teller, it can be manually synced with Teller to reflect the changes.`;
    }


    static getDescription(status: PaymentStatusEnum | string) {
        return this.DESCRIPTIONS[status];
    }
} 
