// Angular Files
import { Injectable } from '@angular/core';

// Payment Integration Files
// For some reason if the PaymentProcessorService was included from just base, the unit tests would fail
import { PaymentProcessorService } from 'apps/public-portal/src/app/payment-integrations/base/interfaces';

// Teller Online Files
import { RedirectPaymentStatusEnum, RedirectVerifyPaymentStatusRequestDto, RedirectVerifyPaymentStatusResponseDto, SharedPaymentIntegrationApiClient } from 'apps/public-portal/src/app/core/api/PaymentIntegrationApiClients';
import { CartService } from 'apps/public-portal/src/app/core/services';
import { RedirectPaymentProcessorResponse } from 'apps/public-portal/src/app/payment-integrations/base';
import { TellerOnlineAppService } from 'teller-online-libraries/core';
import { TellerOnlineWindowService } from 'teller-online-libraries/shared';

@Injectable({
    providedIn: 'root'
})
export class InvoiceCloudService extends PaymentProcessorService {
    //#region PaymentProcessorService fields
    public redirect = true;
    public systemCartGuidKey = "ref";
    //#endregion

    constructor(
        private windowService: TellerOnlineWindowService,
        private appService: TellerOnlineAppService,
        private cartService: CartService,
        public sharedPaymentApi: SharedPaymentIntegrationApiClient,
    ) {
        super(sharedPaymentApi);
    }

    //#region PaymentProcessorService
    public override async checkout(cartGuid: string) {
        try {
            const paymentStart = await this.sharedPaymentApi.startPayment(cartGuid).toPromise()
            this.windowService.navigateExternalUrl(paymentStart.redirectUrl);
            this.windowService.setLocalStorageItem("invoiceCloudPaymentIdentifier", paymentStart.paymentIdentifier);
        } catch(e) {
            this._errorEvent.next('Failed to generate redirect url');
        }
    }

    public override async handlePostbackResponse(params) {
        let verifyPaymentStatusRequest = new RedirectVerifyPaymentStatusRequestDto();
        let paymentIdentifier = this.windowService.getLocalStorageItem("invoiceCloudPaymentIdentifier");
        verifyPaymentStatusRequest.paymentIdentifier = paymentIdentifier;
        verifyPaymentStatusRequest.paymentStatus = RedirectPaymentStatusEnum.Success;

        let response: RedirectVerifyPaymentStatusResponseDto;
        if (paymentIdentifier) {
            response = await this.sharedPaymentApi
                .verifyPaymentStatus(verifyPaymentStatusRequest)
                .toPromise();
        }

        let state: {cartError?: string, emailAddress?: string} = {};
        let redirectUrl = "/";
        let success = response?.responseSuccess;

        if (success) {
            redirectUrl = "/checkout/success/" + this.cartService.cartGuid;
        } else {
            state.cartError = response.responseMessage;
        }

        this.appService.consoleLog(this, state);

        this.windowService.removeLocalStorageItem("invoiceCloudPaymentIdentifier");

        return new RedirectPaymentProcessorResponse({
            paymentIdentifier: paymentIdentifier,
            cartGuid: this.cartService.cartGuid,
            status: success ? "success" : "cancel",
            redirectUrl,
            navigationExtras: { state }
        });
    }

    //#endregion
}
