<section [formGroup]="formGroup" class="layout-grid">
    <div class="payment-method-details__bank-info">
        <mat-form-field class="echeck__name" appearance="standard" floatLabel="always">
            <mat-label>{{ECHECK_FIELDS.checkname}}</mat-label>
            <input matInput
                [formControlName]="ECHECK_FIELDS.checkname"
                placeholder="Name on the account"
                required>
            <mat-error *ngIf="formGroup?.controls?.[ECHECK_FIELDS.checkname]?.touched && formGroup?.controls?.[ECHECK_FIELDS.checkname]?.invalid">
                {{validationService.getFieldErrorMessage(ECHECK_FIELDS.checkname, formGroup.controls[ECHECK_FIELDS.checkname])}}
            </mat-error>
        </mat-form-field>

        <mat-form-field *ngIf="accountTypeEnabled" class="account-type" appearance="standard" floatLabel="always">
            <mat-label>{{ECHECK_FIELDS.checktype}}</mat-label>
            <mat-select
                [formControlName]="ECHECK_FIELDS.checktype"
                required>
                <mat-option selected value="">-- Select One --</mat-option>
                <mat-option *ngFor="let accountType of ECHECK_ACCOUNTTYPELIST" [value]="accountType">
                    {{ accountType }}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="formGroup?.controls?.[ECHECK_FIELDS.checktype]?.touched && formGroup?.controls?.[ECHECK_FIELDS.checktype]?.invalid">
                {{validationService.getFieldErrorMessage(ECHECK_FIELDS.checktype, formGroup.controls[ECHECK_FIELDS.checktype])}}
            </mat-error>
        </mat-form-field>

        <mat-form-field *ngIf="accountOwnerTypeEnabled" class="account-owner-type" appearance="standard" floatLabel="always">
            <mat-label>{{ECHECK_FIELDS.checkowner}}</mat-label>
            <mat-select
                [formControlName]="ECHECK_FIELDS.checkowner"
                required>
                <mat-option selected value="">-- Select One --</mat-option>
                <mat-option *ngFor="let accountOwnerType of ECHECK_ACCOUNTOWNERTYPELIST" [value]="accountOwnerType">
                    {{ accountOwnerType }}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="formGroup?.controls?.[ECHECK_FIELDS.checkowner]?.touched && formGroup?.controls?.[ECHECK_FIELDS.checkowner]?.invalid">
                {{validationService.getFieldErrorMessage(ECHECK_FIELDS.checkowner, formGroup.controls[ECHECK_FIELDS.checkowner])}}
            </mat-error>
        </mat-form-field>

        <mat-form-field class="routing-number" appearance="standard" floatLabel="always">
            <mat-label>{{ECHECK_FIELDS.checkaba}}</mat-label>
            <mat-icon matPrefix svgIcon="echeck-start"></mat-icon>
            <input matInput
                [formControlName]="ECHECK_FIELDS.checkaba"
                placeholder="000000000"
                inputmode="numeric"
                required
                minlength="9"
                maxlength="9">
            <mat-error *ngIf="formGroup?.controls?.[ECHECK_FIELDS.checkaba]?.touched && formGroup?.controls?.[ECHECK_FIELDS.checkaba]?.invalid">
                {{validationService.getFieldErrorMessage(ECHECK_FIELDS.checkaba, formGroup.controls[ECHECK_FIELDS.checkaba])}}
            </mat-error>
        </mat-form-field>

        <mat-form-field class="account-number" appearance="standard" floatLabel="always">
            <mat-label>{{ECHECK_FIELDS.checkaccount}}</mat-label>
            <mat-icon matPrefix svgIcon="echeck-start"></mat-icon>
            <input matInput
                [formControlName]="ECHECK_FIELDS.checkaccount"
                placeholder="000000000"
                inputmode="numeric"
                required
                minlength="4"
                maxlength="17">
            <mat-icon matSuffix svgIcon="echeck-end"></mat-icon>
            <mat-error
                *ngIf="formGroup?.controls?.[ECHECK_FIELDS.checkaccount]?.touched && formGroup?.controls?.[ECHECK_FIELDS.checkaccount]?.invalid">
                {{validationService.getFieldErrorMessage(ECHECK_FIELDS.checkaccount,
                formGroup.controls[ECHECK_FIELDS.checkaccount])}}
            </mat-error>
        </mat-form-field>

        <mat-form-field *ngIf="requireDualEntryForECheck" class="routing-number" appearance="standard" floatLabel="always">
            <mat-label>{{ECHECK_FIELDS.checkabavalidator}}</mat-label>
            <mat-icon matPrefix svgIcon="echeck-start"></mat-icon>
            <input matInput
                [formControlName]="ECHECK_FIELDS.checkabavalidator"
                placeholder="000000000"
                inputmode="numeric"
                required
                [validateMatchField]="formGroup.controls[ECHECK_FIELDS.checkaba].value">
            <mat-error *ngIf="formGroup?.controls?.[ECHECK_FIELDS.checkabavalidator]?.touched && formGroup?.controls?.[ECHECK_FIELDS.checkabavalidator]?.invalid">
                {{validationService.getFieldErrorMessage(ECHECK_FIELDS.checkabavalidator, formGroup.controls[ECHECK_FIELDS.checkabavalidator], validationService.fieldErrors['Confirm Routing Number'])}}
            </mat-error>
        </mat-form-field>

        <mat-form-field *ngIf="requireDualEntryForECheck" class="account-number" appearance="standard" floatLabel="always">
            <mat-label>{{ECHECK_FIELDS.checkaccountvalidator}}</mat-label>
            <mat-icon matPrefix svgIcon="echeck-start"></mat-icon>
            <input matInput
                [formControlName]="ECHECK_FIELDS.checkaccountvalidator"
                placeholder="000000000"
                inputmode="numeric"
                required
                [validateMatchField]="formGroup.controls[ECHECK_FIELDS.checkaccount].value">
            <mat-icon matSuffix svgIcon="echeck-end"></mat-icon>
            <mat-error *ngIf="formGroup?.controls?.[ECHECK_FIELDS.checkaccountvalidator]?.touched && formGroup?.controls?.[ECHECK_FIELDS.checkaccountvalidator]?.invalid">
                {{validationService.getFieldErrorMessage(ECHECK_FIELDS.checkaccountvalidator, formGroup.controls[ECHECK_FIELDS.checkaccountvalidator], validationService.fieldErrors["Confirm Account Number"])}}
            </mat-error>
        </mat-form-field>
    </div>

    <div class="echeck-instructions">
        <div class="echeck-instructions__note">
            <teller-online-icon-info></teller-online-icon-info>
            <span>Where do I find Routing and Account Numbers?</span>
        </div>

        <img class="echeck-instructions__sample" src="assets/images/echeck_latest.png" alt="Sample check image">
    </div>

    <ng-container *ngIf="allowSavePaymentMethod && formGroup?.controls?.rememberPaymentMethod">
        <div class="remember-payment-method" formGroupName="rememberPaymentMethod">
            <mat-checkbox formControlName="checked">Save e-check information</mat-checkbox>
        </div>
    </ng-container>
</section>
